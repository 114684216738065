import * as React from "react"
import Layout from "../components/layout"

// export const pageQuery = graphql`
//   query {
//     site {
//       siteMetadata {
//         title
//       }
//     }
//   }
// `

// interface IndexPageProps extends PageProps {
//   data: {
//     site: {
//       siteMetadata: {
//         title: string
//       }
//     }
//   }
// }

const IndexPage = () => {
  return (
    <Layout pageTitle="Jared Beckham" pageHeader="Jared Beckham">
      {/* <StaticImage
        alt="I made this"
        src="../images/imadethis.jpg"
        placeholder="blurred"
        layout="fixed"
      /> */}
    </Layout>
  )
}

export default IndexPage
